import React from 'react';
import MissionCard from './MissionCard';

export default function MissionSelection({ missions, selectedMission, onMissionSelect, maxHeightClass }) {
  const sortedMissions = [...missions].sort((m1, m2) => new Date(m2.createdAt) - new Date(m1.createdAt));

  return (
    <div className={`overflow-y-auto shadow-lg flex flex-col space-y-4 p-4 rounded-lg ${maxHeightClass}`}>
      {sortedMissions.map((mission) => (
        <div
          key={mission.missionId}
          role="none"
          className={`border rounded cursor-pointer ${
            selectedMission && selectedMission.missionId === mission.missionId ? 'border-blue' : 'border-white'
          }`}
          onClick={() => {
            onMissionSelect(mission);
          }}
        >
          <MissionCard mission={mission} />
        </div>
      ))}
    </div>
  );
}
