import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import GenericService from '../../services/generic.service';
import DroneSelection from './DroneSelection';
import MissionSelection from './MissionSelection';

export default function ScheduleMission() {
  const navigate = useNavigate();
  const [drones, setDrones] = useState([]);
  const [missions, setMissions] = useState([]);
  const [selectedDrone, setSelectedDrone] = useState(null);
  const [selectedMission, setSelectedMission] = useState(null);

  useEffect(() => {
    GenericService.DroneService.getDrones().then((response) => {
      setDrones(response);
    });
    GenericService.MissionService.getMissions().then((response) => {
      setMissions(response);
    });
  }, []);

  const handleDroneSelect = (drone) => {
    setSelectedDrone(drone === selectedDrone ? null : drone);
  };

  const handleMissionSelect = (mission) => {
    setSelectedMission(mission === selectedMission ? null : mission);
  };

  const onSubmit = () => {
    localStorage.setItem('missionId', selectedMission.missionId);
    localStorage.setItem('droneId', selectedDrone.droneId);
    navigate('/manualinspection');
  };

  return (
    <PageWrapper>
      <div className="p-4 w-full">
        <h2 className="ml-4 mt-5 text-xl md:text-2xl lg:text-3xl">Start a mission</h2>
        <div className="flex flex-wrap justify-start ml-4 mt-2 rc:mt-10 gap-4 md:gap-6 lg:gap-8">
          <div className="flex-1 min-w-[280px] md:min-w-[300px] md:ml-[40px] lg:ml-[60px] xl:ml-[30px]">
            <h4 className="text-grey mb-5 text-base md:text-lg">Step 1: Select a drone</h4>
            <DroneSelection
              drones={drones}
              selectedDrone={selectedDrone}
              onDroneSelect={handleDroneSelect}
              maxHeightClass="max-h-[200px] md:max-h-[300px] lg:max-h-[350px] rc:max-h-[350px] xl:max-h-[600px]"
            />
          </div>
          <div className="flex-1 min-w-[280px] md:min-w-[300px]">
            <h4 className="text-grey mb-5 text-base md:text-lg">Step 2: Select a mission</h4>
            <MissionSelection
              missions={missions}
              selectedMission={selectedMission}
              onMissionSelect={handleMissionSelect}
              maxHeightClass="max-h-[200px] md:max-h-[300px] lg:max-h-[350px] rc:max-h-[350px] xl:max-h-[600px]"
            />
          </div>
          <div className="w-full flex justify-center mb-2 md:mr-[40px] lg:mr-[20px] xl:mr-0">
            <Button
              text="Start Mission"
              buttonType="schedule"
              isDisabled={!selectedDrone || !selectedMission}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
