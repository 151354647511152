import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import CenteredMapView from '../AutomaticChecks/CenteredMapView';

export default function MissionCard({ mission }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const onClickExpand = () => {
    setIsExpanded(!isExpanded);
    setIsClicked(!isClicked);
  };

  return (
    <div className="relative w-full">
      <button
        className="absolute top-2 right-2 border-2 border-blue bg-blue text-white p-2 rounded hover:bg-white hover:text-blue"
        type="button"
        onClick={onClickExpand}
      >
        Details
      </button>
      <div className="w-full p-4 bg-white shadow-lg rounded-lg border-l-4 border-green">
        <div className="grid grid-cols-2">
          <div className="col-span-2">
            <div className="grid grid-flow-col">
              <h2 className="text-xl">{mission.name}</h2>
            </div>
          </div>
          <div>
            <p className="mt-2 text-gray-600">Created at: {new Date(mission.createdAt).toLocaleDateString()}</p>
          </div>
        </div>

        <div className="pt-4 flex items-center space-x-5">
          <div className="text-center">
            <FontAwesomeIcon className="w-full" icon={solid('battery-empty')} />
            <p className="w-full">30 %</p>
          </div>

          <div className="text-center">
            <FontAwesomeIcon className="w-full" icon={solid('clock-rotate-left')} />
            <p className="w-full">20 min</p>
          </div>
        </div>
        {isExpanded ? <CenteredMapView missionId={mission.missionId} /> : null}
      </div>
    </div>
  );
}
