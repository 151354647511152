import mapboxgl from 'mapbox-gl';
import proj4 from 'proj4';
import MapGL, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
} from 'react-map-gl';
import React, { useRef, useState, useEffect } from 'react';
import DroneSource from './DroneSource';

// The following is required to stop "npm build" from transpiling mapbox code.
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const initialViewState = {
  longitude: 4.55,
  latitude: 52.23,
  zoom: 6.5,
};

// Define the EPSG:28992 projection
const RDNew = 'EPSG:28992';
const WGS84 = 'EPSG:4326';

// proj4 definition for EPSG:28992 (Amersfoort / RD New)
proj4.defs(
  RDNew,
  '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857330322398,-0.350732676542563,1.87034738360664,4.0812 +units=m +no_defs'
);

export default function DashboardMap({ mapStyle, drones, selectedDrone }) {
  const mapRef = useRef(null);
  const [geoData, setGeoData] = useState(null);
  const [geoDataNoNatura2000, setGeoDataNoNatura2000] = useState(null);

  // Function to fly to a specific point on the map
  const flyTo = (longitude, latitude, isZoomIn) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [longitude, latitude],
        speed: 0.9,
        zoom: isZoomIn ? 16 : 3.5,
        duration: 3000,
      });
    }
  };

  const droneFocusHandler = (longitude, latitude, isZoomIn) => {
    if (!longitude || !latitude) return;
    flyTo(longitude, latitude, isZoomIn);
  };

  // Fetch and reproject GeoJSON data on component mount
  useEffect(() => {
    fetch(
      'https://service.pdok.nl/lvnl/drone-no-flyzones/wfs/v1_0?request=GetFeature&service=WFS&version=2.0.0&typeName=drone-no-flyzones:luchtvaartgebieden&outputFormat=application/json'
    )
      .then((response) => response.json())
      .then((data) => {
        const reprojectedData = {
          ...data,
          features: data.features.map((feature) => {
            const { geometry } = feature;
            if (geometry.type === 'Polygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: [geometry.coordinates[0].map((point) => proj4(RDNew, WGS84, point))],
                },
              };
            }
            if (geometry.type === 'MultiPolygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: geometry.coordinates.map((polygon) =>
                    polygon.map((point) => proj4(RDNew, WGS84, point))
                  ),
                },
              };
            }
            return feature;
          }),
        };
        setGeoData(reprojectedData);
      })
      .catch((err) => console.error('Error loading geo data: ', err));

    fetch(
      'https://service.pdok.nl/lvnl/drone-no-flyzones/wfs/v1_0?request=GetFeature&service=WFS&version=2.0.0&typeName=drone-no-flyzones:luchtvaartgebieden_zonder_natura2000&outputFormat=application/json'
    )
      .then((response) => response.json())
      .then((data) => {
        const reprojectedData = {
          ...data,
          features: data.features.map((feature) => {
            const { geometry } = feature;
            if (geometry.type === 'Polygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: [geometry.coordinates[0].map((point) => proj4(RDNew, WGS84, point))],
                },
              };
            }
            if (geometry.type === 'MultiPolygon') {
              return {
                ...feature,
                geometry: {
                  ...geometry,
                  coordinates: geometry.coordinates.map((polygon) =>
                    polygon.map((point) => proj4(RDNew, WGS84, point))
                  ),
                },
              };
            }
            return feature;
          }),
        };
        setGeoDataNoNatura2000(reprojectedData);
      })
      .catch((err) => console.error('Error loading geo data (zonder Natura 2000): ', err));
  }, []);

  // Trigger flyTo on component mount
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { longitude, latitude } = pos.coords;
      flyTo(longitude, latitude, true);
    });
  }, []);

  const mapStyleURL =
    mapStyle === 'nofly-zones' ? 'mapbox://styles/mapbox/streets-v11' : `mapbox://styles/mapbox/${mapStyle}`;

  return (
    <>
      <MapGL
        ref={mapRef}
        initialViewState={initialViewState}
        mapStyle={mapStyleURL}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      >
        <GeolocateControl position="bottom-right" />
        <FullscreenControl position="bottom-right" />
        <NavigationControl position="bottom-right" />
        <ScaleControl />

        {/* Add the drones as a separate layer */}
        {drones.map((drone) => (
          <DroneSource
            key={drone.droneId}
            drone={drone}
            selectedDrone={selectedDrone}
            onDroneFocusing={droneFocusHandler}
          />
        ))}

        {/* Conditionally render no-fly zone layers */}
        {mapStyle === 'nofly-zones' && geoData && (
          <Source type="geojson" data={geoData}>
            <Layer
              id="drone-zones"
              type="fill"
              paint={{
                'fill-color': '#FF0000', // Red color for no-fly zones
                'fill-opacity': 0.4,
              }}
            />
            <Layer
              id="zone-borders"
              type="line"
              paint={{
                'line-color': '#FF0000',
                'line-width': 2,
              }}
            />
          </Source>
        )}

        {mapStyle === 'nofly-zones' && geoDataNoNatura2000 && (
          <Source type="geojson" data={geoDataNoNatura2000}>
            <Layer
              id="drone-zones-no-natura2000"
              type="fill"
              paint={{
                'fill-color': '#FF0000',
                'fill-opacity': 0.4,
              }}
            />
            <Layer
              id="zone-borders-no-natura2000"
              type="line"
              paint={{
                'line-color': '#FF0000',
                'line-width': 2,
              }}
            />
          </Source>
        )}
      </MapGL>
      <div className="overlay" />
    </>
  );
}
