import axios from 'axios';

/* eslint-disable class-methods-use-this */
class WeatherService {
  async getCurrentWeather() {
    try {
      const position = await this.getCurrentPosition();
      const weatherInfo = await this.getWeatherInfo(position.coords);
      const isLightWind = this.isWindConditionSuitable(weatherInfo.wind.speed);
      const isDay = this.isSunUp(weatherInfo.sys.sunrise, weatherInfo.sys.sunset);
      // The OpenWeatherMap API does not provide the rain atribute if there is no rain
      // That is why we use "let" instead of "const" and we make the check
      let isLightRain;
      if ('rain' in weatherInfo) {
        isLightRain = this.isRainConditionSuitable(weatherInfo.rain['1h']);
      } else {
        isLightRain = true;
      }
      const weatherErrors = this.getErrors(isLightRain, isLightWind, isDay);
      return { isLightRain, isLightWind, isDay, weatherErrors };
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // Gets the geolocation of your device (https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition)
  getCurrentPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  }

  async getWeatherInfo(coords) {
    try {
      // Check for the API key
      if (!process.env.REACT_APP_OPENWEATHER_API_KEY) {
        throw new Error('API key for OpenWeather is not defined in environment variables');
      }

      const response = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${coords.latitude}&lon=${coords.longitude}&units=metric&lang=en&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  isWindConditionSuitable(windSpeed) {
    return windSpeed < 12;
  }

  isRainConditionSuitable(rainLevel) {
    return rainLevel < 1;
  }

  isSunUp(sunrise, sunset) {
    // Convert to seconds because the API works with seconds
    const currentTime = new Date().getTime() / 1000;
    return currentTime >= sunrise && currentTime <= sunset;
  }

  getErrors(isLightRain, isLightWind, isDay) {
    const errors = [];
    if (!isLightRain) {
      errors.push('Expecting rain');
    }
    if (!isLightWind) {
      errors.push('Wind is too strong');
    }
    if (!isDay) {
      errors.push('Visibility is too low');
    }
    return errors;
  }
}

export default WeatherService;
